<template>
  <div>
    <van-nav-bar
        title="对接工作"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
      <template #title>
        <span v-show="!isSearching">对接工作</span>
        <van-field
            class="search-input"
            v-show="isSearching"
            v-model="queryParams.keyword"
            placeholder="请输入搜索内容"
        >
          <template #right-icon>
            <i class="iconfont icon-search search-icon" @click="queryInput"></i>
          </template>
        </van-field>
      </template>
      <template #right>
        <i v-show="!isSearching" class="iconfont icon-search search-icon"></i>
        <span v-show="isSearching" @click="onCancel">取消</span>
      </template>
    </van-nav-bar>
    <van-tabs v-model="selectTabIndex" animated sticky swipeable color="#3476FF" title-active-color="#3476FF" title-inactive-color="#646566" @change="changeTab">
      <van-tab v-for="(item,index) in tabsList" :key="index">
        <template #title>
          {{ item.title }}&nbsp;<i :class="['iconfont',item.icon,'screening-icon']"></i>
        </template>
        <div class="search-flex" v-show="selectTabIndex === 4">
          <div class="search-div">
            <div :class="[selectStatusIndex === index ? 'search-div-select-item' : '','search-div-item']" v-for="(item,index) in checkStatusList" :key="index" @click="changeStatus(index)">
              {{item}}
            </div>
          </div>
          <div class="search-div" style="margin-top: 10px;">
            <div :class="[selectTaskIndex === index ? 'search-div-select-item' : '','search-div-item']" v-for="(item,index) in checkTaskList" :key="index" @click="changeTask(index)">
              {{item}}
            </div>
          </div>
        </div>
        <div class="tab-body">
          <van-list
              v-model="isLoading"
              :finished="isFinished"
              finished-text="已经到底了~"
              @load="onLoadData"
          >
            <div class="body-flex" v-show="selectTabIndex === 4"></div>
            <div class="body-list" v-show="dataList.length > 0">
              <div class="body-list-item" v-for="(item,index) in dataList" :key="index" @click="toDetailsInfo(item)">
                <div class="item-top">
                  <span class="item-active">{{ item.activityName }}</span>
                  <span class="item-car-plate">
                    {{ (item.carPlate || "") + ((item.carPlate && item.designation) ? " | " : "") +  (item.designation || "" )}}
                    <a :href="'tel:' + item.participantNumWorkPhone" class="iconfont icon-dianhua1" v-show="item.participantNumWorkPhone" @click.stop></a>
                  </span>
                </div>
                <div class="item-middle">
                  <div class="item-circle" :style="{backgroundColor: item.status === 0 ? '#D6E3FE' : '#3476FF'}">
                    <i class="iconfont icon-edit-save" :style="{color: item.status === 0 ? '#AAC0ED' : '#FFFFFF'}"></i>
                  </div>
                  <van-image
                      class="item-dashed"
                      :src="require('../../assets/image/' + (item.status === 0 ? 'dashed-grey.png' : ( item.status === 1 ? 'dashed_gradient.png' : 'dashed_blue.png')))"
                  />
                  <div class="item-circle" :style="{backgroundColor: item.status === 2 ? '#3476FF' : '#D6E3FE'}">
                    <i class="iconfont icon-edit-save" :style="{color: item.status === 2 ? '#FFFFFF' : '#AAC0ED'}"></i>
                  </div>
                </div>
                <div class="item-bottom">
                  <div class="item-info">
                    <div class="item-info-text">预计签到</div>
                    <div class="item-info-date">{{ item.takeStartDate }}</div>
                  </div>
                  <div class="item-status">
                    <span>{{item.status === 0 ? '待进行' : item.status === 1 ? '已签到' : '已签退'}}</span>
                  </div>
                  <div class="item-info">
                    <div class="item-info-text">预计签退</div>
                    <div class="item-info-date">{{ item.takeEndDate }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="body-null" v-show="dataList.length === 0">
              <van-image
                  :src="require('../../assets/image/null.png')"
              />
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  personCarButt
} from "@/api/index";

export default {
  name: "orgCheck",
  data() {
    return {
      queryParams: {
        taskType: 0,//任务类型：0：全部任务，1：我的任务
        relateType: null,//对象类别：0：人，1：车，2：人&车
        status: null,//对接状态：0：待进行，1：已签到；2已签退
        keyword: "",
        pageNum: 1,
        pageSize: 10
      },
      isLoading: false,
      isFinished: false,
      isSearching: false,
      isConcat: false,
      selectTabIndex: 0,//0：全部；1：人；2：车；3：人&车；4：筛选
      tabsList: [
        {title: "全部",icon: ""},
        {title: "人员",icon: ""},
        {title: "车辆",icon: ""},
        {title: "人和车",icon: ""},
        {title: "筛选",icon: "icon-shiliangzhinengduixiang2"}
      ],
      selectStatusIndex: 0,
      checkStatusList: ["全部状态","待进行","已签到","已签退"],
      selectTaskIndex: 0,
      checkTaskList: ["全部任务","我的任务"],
      dataList: []
    };
  },
  mounted() {
    let that = this;
    //that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let params = {
        ayId: that.queryParams.taskType ? that.$store.getters.getOPDetails.id : null,
        serialNo: that.$store.getters.getSerialNo,
        keyword: that.queryParams.keyword ? that.queryParams.keyword : null,
        pageNum: that.queryParams.pageNum,
        pageSize: that.queryParams.pageSize,
        relateType: that.queryParams.relateType,
        status: that.queryParams.status
      }
      personCarButt(params).then(res => {
        that.$tool.resValidation(res,() => {
          if(that.isConcat){
            that.dataList = that.dataList.concat(res.data.records);
          }else{
            that.dataList = res.data.records;
          }
          that.isLoading = false;
          if(res.current < res.pages){
            that.queryParams.pageNum = that.queryParams.pageNum + 1;
          }else{
            that.isFinished = true;
          }
        })
      })
    },
    onClickLeft(){
      this.$router.go(-1);
    },
    onClickRight(){
      let that = this;
      that.isSearching = !that.isSearching;
    },
    onLoadData(){
      let that = this;
      if(!that.isFinished){
        if(that.queryParams.pageNum > 1){
          that.isConcat = true;
        }
        that.initData();
      }
    },
    //关键字查询
    queryInput(){
      let that = this;
      that.queryParams.pageNum = 1;
      that.initData();
    },
    onCancel(){
      let that = this;
      that.queryParams.keyword = ""
      that.initData();
    },
    changeTab(index,title){
      let that = this;
      that.queryParams.relateType = (index === 0 || index === 4) ? null : (that.selectTabIndex - 1);
      that.queryParams.pageNum = 1;
      that.initData();
    },
    changeStatus(index){
      let that = this;
      that.queryParams.status = index === 0 ? null : (index - 1);
      that.queryParams.pageNum = 1;
      that.selectStatusIndex = index;
      that.initData();
    },
    changeTask(index){
      let that = this;
      that.queryParams.taskType = index;
      that.queryParams.pageNum = 1;
      that.selectTaskIndex = index;
      that.initData();
    },
    toDetailsInfo(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_details',
        params: item
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
::v-deep .van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  padding: 6px 0px;
}
.search-input{
  width: 100%;
  border: 1px solid #EAF1FF;
  border-radius: 20px;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #3E4576;
  .van-cell{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    background-color: #F6F9FF;
  }
}
.search-icon{
  font-size: 16px;
  color: #1989fa;
}
.screening-icon{
  font-size: 16px;
}
.search-flex{
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0px;
  background-color: #FFFFFF;
  z-index: 1000;
}
.search-div{
  width: 90%;
  padding: 0px 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .search-div-item{
    width: 25%;
    height: 36px;
    line-height: 36px;
    color: #3E4576;
    font-size: 14px;
    text-align: center;
  }
  .search-div-select-item{
    border-radius: 20px;
    background-color: #3476FF;
    color: #FFFFFF;
  }
}
.tab-body{
  width: 100%;
  height: calc(100vh - 102px);
  overflow-y: scroll;
}
.body-flex{
  width: 100%;
  height: 102px;
}
.body-list{
  width: 92%;
  margin: 20px auto;
  .body-list-item{
    width: 100%;
    margin-bottom: 20px;
    padding: 16px 0px;
    background-color: #FFFFFF;
    border-radius: 20px;
    .item-top{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .item-active{
        color: #3E4576;
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
      }
      .item-car-plate{
        color: #3476FF;
        background-color: #D6E3FE;
        font-size:  14px;
        padding: 6px 16px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        a{
          margin-left: 6px;
          font-size: 20px;
          color: #3476FF;
        }
      }
    }
    .item-middle{
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .item-circle{
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 20px;
        background-color: #EAF1FE;
        color: #AAC0ED;
        border: 2px solid #D6E3FE;
        font-size: 14px;
      }
      .item-dashed{
        width: 180px;
        height: 6px;
        margin: 0px 4px;
      }
    }
    .item-bottom{
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .item-info{
        width: 33.3%;
        text-align: center;
        .item-info-text{
          color: #3E4576;
          font-size: 14px;
        }
        .item-info-date{
          margin-top: 6px;
          color: #8B8FAD;
          font-size: 14px;
        }
      }
      .item-status{
        width: 30%;
        text-align: center;
        span{
          color: #FD7300;
          background-color: rgba(253,115,0,0.16);
          padding: 6px 12px;
          border-radius: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
.body-null{
  width: 100%;
  margin: 100px auto;
  text-align: center;
}
</style>